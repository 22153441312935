@keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  #intl-phone-container {
    width: 100%;
    height: 46px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
  }
  
  #intl-phone {
    width: 100%;
    height: 46px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
  }
  
  #intl-phone:focus {
    outline: none;
    border: 1px solid #030A21;
  }
  
  #intl-phone:focus-visible {
    outline: none;
    border: 1px solid #030A21;
  }
  
  .iti {
    width: 100%;
  }
  
  .iti-error {
    border: 1px solid #e22c2c !important; 
  }
  
  .iti:focus-visible {
    outline: none;
    border: 1px solid #030A21;
  }
  
  .iti__selected-flag {
    border: none;
    background-color: transparent !important;
    padding-left: 14px !important;
  }
  
  .iti__dropdown-content {
    margin-top: 10px;
    border-radius: 9px !important;
  }
  